import { getAggregationMeasurementsV2 } from '@api/queries'
import useAccounts from '@hooks/useAccounts/useAccounts'
import useLocationsData from '@hooks/useLocations/useLocationsData'
import { IAggregationMeasurementsProps } from '@interfaces/measurements'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useMemo } from 'react'

type useMeasurementsV2 = Omit<IAggregationMeasurementsProps, 'gpnr' | 'meterId' | 'locationId' | 'contractId'> & {
  skip: boolean
}

export const useMeasurementsV2 = ({ aggregation, startDate, endDate, skip }: useMeasurementsV2) => {
  const { activeAccount } = useAccounts()
  const { selectedItem } = useLocationsData()

  const gpnr = activeAccount.gpnr
  const meterId = selectedItem.addOn?.meterId
  const contractId = selectedItem.addOn?.contractId
  const locationId = selectedItem.addOn?.locationId

  const params = { gpnr, meterId, contractId, locationId, startDate, endDate, aggregation }

  const isEnabled =
    !!gpnr &&
    ((!!meterId && !!contractId) || !!locationId) &&
    dayjs(startDate).isValid() &&
    dayjs(endDate).isValid() &&
    !skip

  const {
    data: apiData,
    isLoading,
    isFetching,
    isPending,
  } = useQuery({
    queryKey: ['aggregationYear', gpnr, meterId, contractId, locationId, startDate, endDate, aggregation],
    queryFn: () => getAggregationMeasurementsV2(params),
    gcTime: 0,
    enabled: isEnabled,
  })

  const mappedData = useMemo(() => {
    if (!apiData) return null

    return {
      ...apiData,
      body: {
        ...apiData.body,
        measurements: apiData.body.measurements.map((measurement) => ({
          ...measurement,
          energySum:
            measurement.energy === null
              ? null
              : Number(measurement.energyMeasured ?? 0) + Number(measurement.energy ?? 0),
          feedInSum:
            measurement.feedIn === null
              ? null
              : Number(measurement.feedIn ?? 0) + Number(measurement.feedInMeasured ?? 0),
        })),
      },
    }
  }, [apiData])

  return { data: mappedData?.body, isLoading, isFetching, isPending }
}
