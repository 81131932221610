import { IMeasurement } from '@interfaces/measurements'
import { CategoricalChartState } from 'recharts/types/chart/types'

export type PayloadType = {
  sumOfRates: number
  consumption: number
  feedIn: number
  feedInMeasured?: number | null
  timestampStartOfPeriod: string
  forcast: number
  energy: number
  energyMeasured?: number | null
}
export interface IAreaMouseClickHelperProps {
  e: CategoricalChartState
  isMobile: boolean
  isTablet: boolean
  isSmallMobile: boolean
  viewportWidth: number
  TOOLTIP_INITIAL_X: number
  TOOLTIP_INITIAL_Y: number
  setTooltipPosition?: CallableFunction
  tooltipOpen: boolean
  setTooltipOpen: CallableFunction
  setPayload?: CallableFunction
}

export const handleSetTooltipPositionAndOpenArea = ({
  e,
  isMobile,
  isTablet,
  isSmallMobile,
  viewportWidth,
  tooltipOpen,
  TOOLTIP_INITIAL_X,
  TOOLTIP_INITIAL_Y,
  setTooltipOpen,
  setTooltipPosition,
  setPayload,
}: IAreaMouseClickHelperProps) => {
  const { activePayload } = e
  const offset = isMobile || isTablet ? 218 : 287
  const chartX = e.chartX ?? 0
  const tooltipPositionX = chartX - offset
  const xTooltipPositionSecondStatemant = tooltipPositionX > 0 ? tooltipPositionX : tooltipPositionX + 143

  if (activePayload?.length) {
    setPayload?.(activePayload[0].payload)
  } else {
    setPayload?.(undefined)
  }

  if (isSmallMobile) {
    setTooltipPosition?.({ x: TOOLTIP_INITIAL_X, y: TOOLTIP_INITIAL_Y })
  } else {
    setTooltipPosition?.({
      x: chartX + offset < Math.min(viewportWidth, 1100) ? chartX : xTooltipPositionSecondStatemant,
      y: TOOLTIP_INITIAL_Y,
    })
  }
  !tooltipOpen && setTooltipOpen(true)
}

export const calculateMaxMinDomain = (data: IMeasurement[], maxPower?: number) => {
  const sumOfRates = data.map((item) => Number(item.sumOfRates)).filter((item) => !Number.isNaN(item))
  const prosumerVals = data.map((item) => Number(item.feedIn)).filter((item) => !Number.isNaN(item))
  const consumptionVals = data.map((item) => Number(item.consumption)).filter((item) => !Number.isNaN(item))
  const energyVals = data.map((item) => Number(item.energy)).filter((item) => !Number.isNaN(item))
  const maxPowerValues = data.map((item) => Number(item.maxPower)).filter((item) => !Number.isNaN(item))
  const maxPowerInMonth = data.map((item) => Number(item.maxPowerInMonth)).filter((item) => !Number.isNaN(item))
  const reactivePower = data.map((item) => Number(item.reactivePower)).filter((item) => !Number.isNaN(item))
  const maxPowerVal = maxPower ? [maxPower] : []
  const max = Math.max(
    ...sumOfRates,
    ...prosumerVals,
    ...consumptionVals,
    ...energyVals,
    ...maxPowerVal,
    ...maxPowerValues,
    ...maxPowerInMonth,
    ...reactivePower
  )
  const min = Math.min(
    ...sumOfRates,
    ...prosumerVals,
    ...consumptionVals,
    ...energyVals,
    ...maxPowerVal,
    ...maxPowerValues,
    ...maxPowerInMonth,
    ...reactivePower
  )
  return { min, max }
}

export const calculateTicks = (minValue: number, maxValue: number, tickCount: number) => {
  const range = maxValue - minValue

  if (minValue === maxValue) return [maxValue]

  const interval = range / (tickCount - 1)

  const ticks = []
  if (maxValue / tickCount <= 0.01) {
    ticks.push(0, maxValue)
  } else {
    for (let i = 0; i < tickCount; i++) {
      const tick = minValue + interval * i
      ticks.push(tick.toFixed(2))
    }
  }
  return ticks
}

export type IObject =
  | {
      [Property in keyof IMeasurement]: string | string[]
    }
  | { [key: string]: string | string[] | IObject }

export const objectsEqual = (o1: IObject | IMeasurement, o2: IObject | IMeasurement): boolean => {
  const keys1 = Object.keys(o1) as (keyof IObject | keyof IMeasurement)[]
  const keys2 = Object.keys(o2) as (keyof IObject | keyof IMeasurement)[]
  if (keys1.length !== keys2.length) return false

  for (const key of keys1) {
    if (o1[key] !== o2[key]) return false
  }
  return true
}

export const arraysEqual = (a1: IObject[] | IMeasurement[], a2: IObject[] | IMeasurement[]) => {
  if (a1.length !== a2.length) {
    return false
  }
  for (let i = 0; i < a1.length; i++) {
    if (!objectsEqual(a1[i], a2[i])) {
      return false
    }
  }
  return true
}

export const isItNullValue = (value: unknown) => value === null

export const findNullValueIndexes = (data: IMeasurement[]) => {
  const nullValueIndexes: number[] = []

  data.forEach((measurment, index) => {
    if (
      isItNullValue(measurment.feedIn) ||
      isItNullValue(measurment.sumOfRates) ||
      isItNullValue(measurment.consumption) ||
      isItNullValue(measurment.energy)
    ) {
      nullValueIndexes.push(index)
    }
  })

  return nullValueIndexes
}

export const groupNullIndexes = (data: IMeasurement[]) => {
  const nullValueIndexes = findNullValueIndexes(data)

  if (nullValueIndexes.length === 0) return []

  const result = []
  let tempArray = [nullValueIndexes[0]]

  for (let i = 1; i < nullValueIndexes.length; i++) {
    if (nullValueIndexes[i] === nullValueIndexes[i - 1] + 1) {
      tempArray.push(nullValueIndexes[i])
    } else {
      result.push([tempArray[0], tempArray[tempArray.length - 1]])
      tempArray = [nullValueIndexes[i]]
    }
  }

  result.push([tempArray[0], tempArray[tempArray.length - 1]])

  return result
}
